<template>
  <b-container class="home">
    <!--
    @drop.prevent="captureDrop"
    @dragenter.prevent="validateDrop"
    @dragover.prevent="validateDrop"
    -->
    <b-row>
      <b-col cols="12" lg="10" offset-lg="1">
        <h3>Import Articles from Grundstock (Excel)</h3>
        <b-form-select class="my-1" v-model="category" :options="categoryOptions"
          :state="categoryIsValid"></b-form-select>
        <b-form-select class="my-1" v-model="targetAgency" :options="targetAgencyOptions" :state="true"></b-form-select>
        <b-file id="fileDrop" v-model="file" :state="fileIsValid" class="big-file-input"
          placeholder="Choose a file or drop it here..." drop-placeholder="Drop file here..."
          @input="processFileInput"></b-file>
        <!-- <b-list-group v-if="fileDefined">
          <b-list-group-item>{{ this.file.name }}</b-list-group-item>
        </b-list-group>-->
        <!-- <b-button class="mx-1 my-1" @click="clearFiles">Reset form</b-button> -->
        <b-button class="mx-1 my-1" @click="validateFiles" variant="info" :disabled="!submitIsValid">Validate
          file</b-button>
        <b-button class="mx-1 my-1" @click="submitFiles" variant="primary" :disabled="!submitIsValid">Submit
          file</b-button>
      </b-col>
    </b-row>
    <b-modal ref="validationModal" title="Validating Grundstock" hide-footer no-close-on-backdrop no-close-on-esc
      hide-header-close>
      <b-list-group>
        <b-list-group-item v-for="stage in validationInfo.stages" :key="stage.index"
          class="flex-column justify-content-between">
          <b-icon v-if="stage.state == STAGE_STATES.WAITING" icon="arrow-clockwise"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.IN_PROGRESS" icon="arrow-clockwise" variant="primary"
            animation="spin"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.FINISHED" icon="check-circle" variant="success"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.ERROR" icon="x-circle" variant="danger"></b-icon>
          {{ stage.display_text }}
          <b-progress v-if="stage.progressTotal" :value="stage.progress" :max="stage.progressTotal" show-progress>
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="validationInfo.finished">
        <h3>Result</h3>
        <p>{{ validationInfo.resultText }}</p>
        <ul>
          <li v-for="line in validationInfo.resultLines" :key="line">{{ line }}</li>
        </ul>
      </div>
      <b-button block @click="closeValidationModal" :disabled="!validationInfo.finished">Close</b-button>
    </b-modal>
    <b-modal ref="submitModal" title="Importing Grundstock" hide-footer no-close-on-backdrop no-close-on-esc
      hide-header-close>
      <b-list-group>
        <b-list-group-item v-for="stage in submitInfo.stages" :key="stage.index"
          class="flex-column justify-content-between">
          <b-icon v-if="stage.state == STAGE_STATES.WAITING" icon="arrow-clockwise"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.IN_PROGRESS" icon="arrow-clockwise" variant="primary"
            animation="spin"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.FINISHED" icon="check-circle" variant="success"></b-icon>
          <b-icon v-if="stage.state == STAGE_STATES.ERROR" icon="x-circle" variant="danger"></b-icon>
          {{ stage.display_text }}
          <b-progress v-if="stage.progressTotal" :value="stage.progress" :max="stage.progressTotal" show-progress>
            <b-progress-bar :value="stage.progress">
              <span>
                <strong>{{ stage.progress.toFixed(1) }} %</strong>
              </span>
            </b-progress-bar>
          </b-progress>
        </b-list-group-item>
      </b-list-group>
      <div v-if="submitInfo.finished">
        <h3>Result</h3>
        <p>{{ submitInfo.resultText }}</p>
        <ul>
          <li v-for="line in submitInfo.resultLines" :key="line">{{ line }}</li>
        </ul>
      </div>
      <b-button block @click="closeSubmitModal" :disabled="!submitInfo.finished">Close</b-button>
    </b-modal>
  </b-container>
</template>

<style lang="scss">
// Custom SCSS rules from this GH issue (Bigger drop zone for file uploads):
// https://github.com/bootstrap-vue/bootstrap-vue/issues/4570#issuecomment-573729870
$file-height: 100px;

/* Might need adjusting depending on the chosen height */
$padding-percent: 0.4;

.big-file-input {
  height: $file-height;
}

.big-file-input>label {
  text-align: center;
  height: $file-height;

  /* Add a percent of height as padding-top for content */
  padding-top: $file-height * $padding-percent;
}

// This sets height and padding for the "Browse" button
.big-file-input>label::after {
  height: $file-height - 2px;
  padding-top: ($file-height - 2px) * $padding-percent;
}
</style>

<script>
import api from "@/backendapi";
import { defaultToastBody, defaultToastConfig } from "@/util";

const _STAGE_STATES = {
  WAITING: 10,
  IN_PROGRESS: 20,
  FINISHED: 30,
  ERROR: -100
};

const _resetValidationInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server validation"
      }
    }
  };
};

const _resetSubmitInfo = () => {
  return {
    finished: false,
    resultText: "",
    resultLines: [],
    stages: {
      UPLOAD: {
        index: 0,
        state: _STAGE_STATES.WAITING,
        display_text: "Uploading file",
        progress: 0,
        progressTotal: 100
      },
      WAIT_RESP: {
        index: 1,
        state: _STAGE_STATES.WAITING,
        display_text: "Waiting for server processing"
      }
    }
  };
};

export default {
  name: "Ingest",
  data: function () {
    return {
      STAGE_STATES: _STAGE_STATES,
      file: null,
      category: null,
      categoryOptions: [
        { value: null, text: "Select a category", disabled: true },
        { value: "food", text: "Food" },
        { value: "devfood", text: "DevFood" }
      ],
      targetAgency: null,
      // files: [],
      // currentStage: "",
      //submitStage: true,
      validationInfo: _resetValidationInfo(),
      submitInfo: _resetSubmitInfo()
    };
  },
  computed: {
    getUser: function () {
      return this.$store.state.user;
    },
    targetAgencyOptions: function () {
      return this.$store.state.agencyOptions;
    },
    fileDefined: function () {
      return this.file !== null;
    },
    fileIsValid() {
      if (this.file == null) {
        return null;
      }
      return this._validateFile(this.file);
    },
    categoryIsValid() {
      if (this.category == null) {
        return null;
      }
      return this.category ? true : false;
    },
    submitIsValid() {
      return (
        this._validateFile(this.file) && this._validateCategory(this.category)
      );
    }
  },
  methods: {
    _validateCategory(category) {
      return category ? true : false; // Truthy check
    },
    _validateFile(file) {
      if (!this.file) {
        return false;
      }
      let fparts = file.name.split(".");
      if (fparts.length <= 1 || fparts[fparts.length - 1] !== "xlsx") {
        return false;
      }
      return true;
    },
    captureDrop(e) {
      let droppedFiles = e.dataTransfer.files;
      console.log("drop");
      e.preventDefault();
      e.stopPropagation();
      console.log(e);
      if (!droppedFiles) return;
      // this tip, convert FileList to array, credit:
      //   https://www.smashingmagazine.com/2018/01/drag-drop-file-uploader-vanilla-js/
      [...droppedFiles].forEach(f => {
        console.log(f);
      });
    },
    validateDrop(e) {
      console.log("validate");
      console.log(e);
    },
    processFileInput(file) {
      // TODO: Validation on file here?
      console.log(file);
    },
    clearFiles() {
      this.file = null;
    },
    // removeLastFile() {
    //   this.files.pop();
    // },
    // printFiles() {
    //   console.log(this.files);
    // },
    /**
     * Checks if files are defined. Return true if yes, false if no.
     *
     * Additionally will toast a warning about files being incomplete.
     */
    _checkFiles() {
      if (!this.fileDefined) {
        let toastConfig = defaultToastConfig();
        toastConfig.title = "No file set!";
        toastConfig.variant = "warning";
        this.$bvToast.toast(
          `Please browse or drop a file on the file box.`,
          toastConfig
        );
        return false;
      }
      return true;
    },
    async validateFiles() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.validationInfo = _resetValidationInfo();
      this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["validationModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      // let file = this.files[0];
      let _file = this.file;
      // TODO: Error handling missing file here?
      fileData.append("excel", _file, _file.name);
      fileData.set("targetAgency", this.targetAgency);
      let requestConfig = {
        onUploadProgress: progEvt => {
          this.validationInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.validationInfo.stages.UPLOAD.progress >= 99.999999) {
            this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        }
      };
      try {
        let res = await api.postValidateGrundImport(
          localStorage.token,
          this.category,
          fileData,
          requestConfig
        );
        //this.validationInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
        this._setValidationResult(res);
        // Covering in case the typo gets fixed later
        // if (
        //   res.validation_status == "Succes" ||
        //   res.validation_status == "Success"
        // ) {
        this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
        // } else {
        //   this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        // }
        this.validationInfo.finished = true;
      } catch (err) {
        console.log(err);
        this.validationInfo.finished = true;
        this.validationInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.$store.commit("setLeaveGuard", false);
      }
    },
    _setValidationResult(respData) {
      // `respData` is a list of objects with keys `data`, `sg`, `skip`, `validation_message`. See `IngestArticle` class at ingest repo.
      console.log(respData)
      this.validationInfo.resultText = `Parsed ${respData.length} from ingest. Found ${respData.filter(x => !x.skip).length} new articles to import. Found issues:`;
      this.validationInfo.resultLines = respData.filter(x => x.skip).map(x => `${x.data.sg_product_name_int
} (SG Wave id ${x.data.sg_wave_id}): ${x.validation_message}`);
    },
    closeValidationModal() {
      this.$refs["validationModal"].hide();
    },
    async submitFiles() {
      // Preflight check
      if (!this._checkFiles()) {
        return;
      }
      // Setup before showing modal
      this.submitInfo = _resetSubmitInfo();
      this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.IN_PROGRESS;
      this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.IN_PROGRESS;
      this.$store.commit("setLeaveGuard", true);
      this.$refs["submitModal"].show();
      // Step 1: Upload file
      let fileData = new FormData();
      // let file = this.files[0];
      let _file = this.file;
      // TODO: Error handling missing file here?
      fileData.append("excel", _file, _file.name);
      fileData.set("targetAgency", this.targetAgency);
      let requestConfig = {
        onUploadProgress: progEvt => {
          this.submitInfo.stages.UPLOAD.progress =
            (progEvt.loaded / progEvt.total) * 100;
          if (this.submitInfo.stages.UPLOAD.progress >= 99.999999) {
            this.submitInfo.stages.UPLOAD.state = _STAGE_STATES.FINISHED;
          }
        }
      };
      try {
        let res = await api.postSubmitGrundImport(
          localStorage.token,
          this.category,
          fileData,
          requestConfig
        );
        this._setSubmitResult(res);
        // if (res.validation_status == "Succes") {
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.FINISHED;
        // } else {
        //   this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        // }
      } catch (err) {
        console.log(err);
        this.submitInfo.stages.WAIT_RESP.state = _STAGE_STATES.ERROR;
        let resp = err.response;
        this.$bvToast.toast(defaultToastBody(resp), defaultToastConfig(resp));
      } finally {
        this.submitInfo.finished = true;
        this.$store.commit("setLeaveGuard", false);
      }
    },
    _setSubmitResult(respData) {
      // `respData` is a list of objects with keys `data`, `sg`, `skip`, `validation_message`. See `IngestArticle` class at ingest repo.
      console.log("Set Resp data")
      console.log(respData)
      this.submitInfo.resultText = `Parsed ${respData.length} from ingest. Imported ${respData.filter(x => !x.skip).length} new articles. Found issues:`;
      // TODO error here
      this.submitInfo.resultLines = respData
        .filter(x => x.skip)
        .map(x => {
          let name = x.data.sg_product_name_int
          let id = x.data.sg_wave_id
          let message = x.validation_message
          return `${name} (SG Wave id ${id}): ${message}`
        });
      // this.submitInfo.resultText = JSON.stringify(respData);
      // if (respData.structure_valid == "Failed") {
      //   this._setSubmitFailed(respData);
      // } else {
      //   this._setSubmitOK(respData);
      // }
    },
    // _setSubmitFailed(respData) {
    //   this.submitInfo.resultText = respData.message;
    //   this.submitInfo.resultLines = [
    //     respData.structure_valid ? "Structure OK" : "Structure INVALID!",
    //     respData.invalid_rows
    //       ? `Number of invalid entries: ${respData.invalid_rows.length} . Errors found:`
    //       : "Content OK"
    //   ];
    //   respData.invalid_rows.forEach(row_data => {
    //     this.validatsubmitInfoionInfo.resultLines.push(
    //       // +1 for 1-index and +1 for header row
    //       `Row ${row_data.row_index + 2}; Reason: "${row_data.reason}"`
    //     );
    //   });
    // },
    // _setSubmitOK(respData) {
    //   this.submitInfo.resultText = respData.message;
    //   let _reportLines = [
    //     respData.new_ians ? `Created ${respData.new_ians} new IANs.` : "",
    //     respData.updated_ians
    //       ? `Updated ${respData.updated_ians} existing IANs.`
    //       : "",
    //     respData.new_styles
    //       ? `Created ${respData.new_styles}  new Styles.`
    //       : "",
    //     respData.updated_styles
    //       ? `Updated ${respData.updated_styles} existing Styles.`
    //       : "",
    //     respData.new_suppliers
    //       ? `Defined ${respData.new_suppliers} new Suppliers`
    //       : "",
    //     respData.new_packaging_agencies
    //       ? `Defined ${respData.new_packaging_agencies} new Packaging Agencies`
    //       : ""
    //   ];
    //   console.log(_reportLines);
    //   _reportLines = _reportLines.filter(line => line); // Filter out falsies
    //   console.log(_reportLines);
    //   this.submitInfo.resultLines = _reportLines;
    // },
    closeSubmitModal() {
      this.$refs["submitModal"].hide();
    }
  },
  components: {},
  created: function () {
    this.$store.dispatch("getAgencies")
  }
};
</script>
